
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import * as dat from 'lil-gui'

// Panel
const toggleButton = document.getElementById('toggleButton');
const uploadPanel = document.getElementById('uploadPanel');

let isPanelVisible = false;

toggleButton.addEventListener('click', function() {
    if (isPanelVisible) {
        uploadPanel.classList.remove('retracted');
        toggleButton.innerText = 'Show Panel';
    } else {
        uploadPanel.classList.add('retracted');
        toggleButton.innerText = 'Hide Panel';
    }

    isPanelVisible = !isPanelVisible;
});

// Three.js
const HEIGHT = 4160
const WIDTH = 2048 

const imageSide = document.querySelector("#imageSide")
const displaySide = document.querySelector("#displaySide") 

const imageTop = document.querySelector("#imageTop")
const displayTop = document.querySelector("#displayTop") 


/**
 * Base
 */
// Debug
const gui = new dat.GUI()
gui.show(false)

// Canvas
const canvas = document.querySelector('canvas.webgl')
// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const cylinderTexture = textureLoader.load('textures/Lamp/f4c53d59cea4136d76221b0d37875cdd.webp')
const cylinderDisplacementMap = textureLoader.load('textures/Lamp/456df10d1b8653de2b725d1bd1ffdd4a.webp')
const topCoverTexture = textureLoader.load('textures/Lamp/5d6391737feb57b473cbb6755d56e4bb.webp')
const topCoverDisplacementMap = textureLoader.load('textures/Lamp/c3c3d03b5113d9a0635b4a67b7a7d3ff.webp')
const topCoverAlphaMap = textureLoader.load('textures/Lamp/abe33e3221a40cdcdb5e85a95fd91f58.webp')

imageTop.addEventListener("change", (event) =>{
    
    let uploaded_imageTop = event.target.files[0] 
    
    let reader = new FileReader()
    
    reader.readAsDataURL(uploaded_imageTop)

    reader.onload = (event) => {

        let image_url = event.target.result

        displayTop.style.backgroundImage = `url(${image_url})`
        displayTop.style.backgroundRepeat = "no-repeat"
        displayTop.style.backgroundSize = "contain"
        displayTop.style.backgroundPosition = "center"

        let image = document.createElement("img")
        image.src = image_url

        image.onload = (e) => {

            // Preparing canvas
            let canvas = document.createElement("canvas")
            canvas.width = WIDTH
            canvas.height = HEIGHT
            let ctx = canvas.getContext("2d")   

            // Rotating canvas
            ctx.translate(canvas.width/2,canvas.height/2);
            ctx.rotate(90*Math.PI/180);
            ctx.drawImage(image,-canvas.height/2,-canvas.width/2,HEIGHT,WIDTH);

            // Converting image to grayscale
            var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var d = imgData.data;
            for (var i = 0; i < d.length; i += 4) {
                var med = (d[i] + d[i + 1] + d[i + 2]) / 3;
                d[i] = d[i + 1] = d[i + 2] = med;
                if(med > 214) {
                    d[i] = d[i+1] = d[i+2] = 214
                }
                if (med < 5) {
                    d[i] = d[i+1] = d[i+2] = 5
                }
                // Remove transparency for transparent pixels
                if(d[i] === 0 && d[i + 1] === 0 && d[i + 2] === 0 && d[i+3] !== 255) {
                    d[i] = d[i+1] = d[i+2] = 240
                    d[i+3] = 255
                }
            }
            ctx.putImageData(imgData, 0, 0);

            // Saving rotated image
            let topCustomTexture = canvas.toDataURL("image/png") 

            // Inverting colors
            ctx.globalCompositeOperation='difference';
            ctx.fillStyle='white';
            ctx.fillRect(-canvas.height/2,-canvas.width/2,HEIGHT,WIDTH);

            // Saving inverted image
            let topCustomDisplacementMap = canvas.toDataURL("image/png") 

            // Updating cylinder side
            const customTexture = textureLoader.load(topCustomTexture)
            const customDisplacementMap = textureLoader.load(topCustomDisplacementMap)

            if(topCover) {
                topCover.material = new THREE.MeshStandardMaterial( {
                    map:customTexture,
                    displacementMap: customDisplacementMap,
                    displacementScale:0.5,
                    alphaMap:topCoverAlphaMap,
                    transparent:true,
                });
            }
        }
    }
})

imageSide.addEventListener("change", (event) =>{
    
    let uploaded_imageSide = event.target.files[0] 
    
    let reader = new FileReader()
    
    reader.readAsDataURL(uploaded_imageSide)

    reader.onload = (event) => {

        let image_url = event.target.result

        displaySide.style.backgroundImage = `url(${image_url})`
        displaySide.style.backgroundRepeat = "no-repeat"
        displaySide.style.backgroundSize = "contain"
        displaySide.style.backgroundPosition = "center"

        let image = document.createElement("img")
        image.src = image_url

        image.onload = (e) => {

            // Preparing canvas
            let canvas = document.createElement("canvas")
            canvas.width = WIDTH
            canvas.height = HEIGHT
            let ctx = canvas.getContext("2d")

            // Rotating canvas
            ctx.translate(canvas.width/2,canvas.height/2);
            ctx.rotate(90*Math.PI/180);
            ctx.drawImage(image,-canvas.height/2,-canvas.width/2,HEIGHT,WIDTH);

            // Converting image to grayscale
            var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var d = imgData.data;
            for (var i = 0; i < d.length; i += 4) {
                var med = (d[i] + d[i + 1] + d[i + 2]) / 3;
                d[i] = d[i + 1] = d[i + 2] = med;
                if (med < 5) {
                    d[i] = d[i+1] = d[i+2] = 5
                }
                if(med > 214) {
                    d[i] = d[i+1] = d[i+2] = 214
                }
                // Remove transparency for transparent pixels
                if(d[i] === 0 && d[i + 1] === 0 && d[i + 2] === 0 && d[i+3] !== 255) {
                    d[i] = d[i+1] = d[i+2] = 240
                    d[i+3] = 255
                }
            }
            ctx.putImageData(imgData, 0, 0);
            
            // Saving rotated image
            let cylinderCustomTexture = canvas.toDataURL("image/png") 

            // Inverting colors
            ctx.globalCompositeOperation='difference';
            ctx.fillStyle='white';
            ctx.fillRect(-canvas.height/2,-canvas.width/2,HEIGHT,WIDTH);

            // Saving inverted image
            let cylinderCustomDisplacementMap = canvas.toDataURL("image/png") 

            // Updating cylinder side
            const customTexture = textureLoader.load(cylinderCustomTexture)
            const customDisplacementMap = textureLoader.load(cylinderCustomDisplacementMap)

            if (cylinder) {
                cylinder.material =  new THREE.MeshStandardMaterial({
                    map: customTexture,
                    displacementMap: customDisplacementMap,
                    displacementScale: 0.75
                })
            }
        }
    }
})

/**
 * Models
 */

const gltfLoader = new GLTFLoader()


gltfLoader.load(
    '/models/Lamp_Base_10cmDiameter_v6.gltf',
    (gltf) => {
        gltf.scene.scale.set(0.22,0.22,0.22)
        gltf.scene.position.set(-11,-15,11)
        gltf.scene.rotateX(-Math.PI/2)
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/models/Lamp_interchangeable_top_litho.gltf',
    (gltf) => {
        gltf.scene.scale.set(0.022,0.022,0.022)
        gltf.scene.position.set(-11,14.5,-11)
        gltf.scene.rotateX(Math.PI/2)
        scene.add(gltf.scene)
    }
)

/**
 * Objects
 */

// Top Cover
const topCoverGeometry = new THREE.PlaneGeometry(20,20,1000,1000)
const topCoverMaterial = new THREE.MeshStandardMaterial( {
    map:topCoverTexture,
    displacementMap: topCoverDisplacementMap,
    displacementScale:0.5,
    alphaMap:topCoverAlphaMap
} );
topCoverMaterial.transparent = true
const topCover = new THREE.Mesh( topCoverGeometry, topCoverMaterial );
topCover.position.set(0,15,0)
topCover.rotateX(- Math.PI / 2)
scene.add( topCover );

// Cylinder Body
 class CustomSinCurve extends THREE.Curve {
	constructor( scale = 1 ) {
		super();
		this.scale = scale;
	}

	getPoint( t, optionalTarget = new THREE.Vector3() ) {
		const tx = 0;
		const ty = t * 3 - 1.5;
		const tz = 0;

		return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
	}
}

const path = new CustomSinCurve( 10 );
const cylinderGeometry = new THREE.TubeGeometry( path, 1000,10,3000,false )
const cylinderMaterial = new THREE.MeshStandardMaterial({
    map: cylinderTexture,
    displacementMap:cylinderDisplacementMap,
    displacementScale:0.75,
    wireframe:false
})
const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial)
// cylinder.position.set(-5,-5,0)
scene.add(cylinder)

// Plane
const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(100, 100, 10, 10),
    new THREE.MeshStandardMaterial({
        color: '#777777',
        wireframe:true
    })
)
plane.rotation.x = - Math.PI * 0.5
plane.position.y = - 16
scene.add(plane)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0x404040, 3)
scene.add(ambientLight)

// Directionnal Light
// const directionalLight = new THREE.DirectionalLight(0xffffff, 3)
// directionalLight.position.set(0, 0, 10)
// scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 150)
camera.position.x = 15
camera.position.y = 15
camera.position.z = 20
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enablePan = false
controls.minDistance = 20
controls.maxDistance = 75
controls.maxPolarAngle = Math.PI / 1.5
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()
    if( camera.position.y < -16) {
        camera.position.y = -16
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()